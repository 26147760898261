import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';
import { useUser } from '../hooks';
import useAuth from '../hooks/useAuth';
import axios from 'axios';
import { useEffect } from 'react';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles: string[];
    requiredPermission?: string; // The specific project key to check (e.g., "bug")
};

const homePage = ['/', '/', '/', '/tickets/bug', '/tickets/bug'];

const PrivateRoute = ({
    component: RouteComponent,
    roles,
    requiredPermission,
    ...rest
}: PrivateRouteProps) => {
    const location = useLocation();
    const [loggedInUser] = useUser();
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const api = new APICore();

    useEffect(() => {
        if (api.isUserAuthenticated() === false) {
            navigate('/login');
        } else if (!auth.email) {
            axios.get('/home/', { withCredentials: true })
                .then((response) => {
                    const {
                        main_role, first_name, last_name, email,
                        projects, users, last_login, username, notifications
                    } = response.data;
                    setAuth({ username, last_login, first_name, last_name, email, main_role, current_role: main_role, projects, users, notifications });
                })
                .catch(err => {
                    if (err === 'Invalid credentials') {
                        localStorage.removeItem('LoggedIn');
                        navigate('/login');
                    } else if (err === 'change password') {
                        navigate('/ChangePassword');
                    }
                });
        }
    }, [auth.email, setAuth]);

    const userRoles = auth.current_role?.split(',').map(Number) || [];
    const userProjects = auth.projects || [];

    // Authorization logic based on roles
    const isAuthorizedByRole = roles.some(role => userRoles.includes(Number(role)));

    // Authorization logic based on projects
    const hasProjectPermission = requiredPermission
        ? userProjects.some((project:any) => project[requiredPermission] === true)
        : true;

    if (!isAuthorizedByRole || !hasProjectPermission) {
        const fallbackRole = Math.min(...userRoles);
        navigate(homePage[fallbackRole]);
        return null; // Prevent rendering unauthorized components
    }

    return <RouteComponent />;
};

export default PrivateRoute;
